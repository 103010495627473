//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: [
        'content'
    ],
    data() {
        return {
            punches: 0,
            punchesNeeded: 3,
        }
    },
    mounted() {
        this.getBalance()
    },
    computed: {
        user() {
            return this.$store.state.activeUser
        },
        location() {
            return this.$store.state.location
        },
        loyalty() {
            if (this.location) {
                if (this.location.loyalty === true) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        hasCatering() {
            if (this.$store.state.location) {
                // console.log('there is a location::: ', this.$store.state.location)
                if (this.$store.state.location.offerings && this.$store.state.location.offerings.filter(item => item === 'Catering').length>0) {
                    // console.log('has catering in offerings::::: ', this.$store.state.location.offerings)
                    return true
                } else {
                    // console.log('no caterign')
                    return false
                }
            } else {
                // console.log('no location')
                return false
            }
        }
    },
    methods: {
        scrubLink(link) {
            return link.replace('pages/', '')
        },
        async getBalance() {
            if (this.user && this.user.loyalty === true) {
                let balance = await this.$api.getBalance()
                if (balance) {
                    this.punches = balance.points
                    this.punchesNeeded = 3 - this.punches
                }
            }
        },
    }
}
